import { KeyValue } from '@angular/common';
import { EntityRecord } from './entity';
import { GenericTreeNode } from './tree-view-flat-node';

export class Index extends GenericTreeNode {
  indexPackages: EntityRecord[];
  indexCalculation: string;
  indexCurrency: string;
  indexCustomIndex: boolean;
  indexGroup: string;
  indexGroupId: number;
  indexId: number;
  indexIsActive: boolean;
  indexIsLicensed: boolean;
  indexIssuer: string;
  indexIssuerId: number;
  indexKind: string;
  indexKindId: number;
  indexLicenseIssue: boolean;
  indexName: string;
  indexNofLicensed: number;
  indexTickerCode: string;
  indexIsin: string;
  indexImportError: string;
  indexImportId: number;
  indexImportStatus: string;
  indexImportStatusId: number;
  indexConstituentPricesUsed: string;
  indexSector: string;
  indexUnderlying: string;
}

export class IndexesParticulars {
  indexBaseDate: Date;
  indexBaseValue: number;
  indexBenchmarkFamily: string;
  indexCalculation: string;
  indexClassification: string;
  indexClassificationId: number;
  indexClassificationOptions: KeyValue<string, string>;
  indexCountryIso: string;
  indexCreationSource: string;
  indexCreationSourceId: number;
  indexCurrency: string;
  indexCusip: string;
  indexEsgRelated: boolean;
  indexEsmaRegistered: boolean;
  indexFamily: string;
  indexFamilyId: number;
  indexFamilyOptions: KeyValue<string, string>;
  indexGroup: string;
  indexGroupId: number;
  indexId: number;
  indexInceptionDate: Date;
  indexInfo1: string;
  indexInfo2: string;
  indexIsActive: boolean;
  indexIsin: string;
  indexIssuer: string;
  indexIssuerCode: string;
  indexIssuerId: number;
  indexKind: string;
  indexKindId: number;
  indexKindOptions: KeyValue<string, string>;
  indexMaturityBand: string;
  indexMdate: Date;
  indexMuser: string;
  indexName: string;
  indexRating: string;
  indexRegion: string;
  indexReview: boolean;
  indexSftpFolder: string;
  indexSourceOptions: KeyValue<string, string>;
  indexSustainability: string;
  indexSustainabilityId: number;
  indexSustainabilityOptions: KeyValue<string, string>;
  indexSymbol: string;
  indexTickerCode: string;
  indexType: string;
  indexValor: string;
  indexWeightingMethod: string;
  indexConstituentPricesUsed: string;
  indexSector: string;
  indexUnderlying: string;

  public constructor(init?: Partial<IndexesParticulars>) {
    Object.assign(this, init);
  }
}

export class IndexesParticularsRequest {
  indexBaseDate: string;
  indexBaseValue: number;
  indexBenchmarkFamily: string;
  indexCalculation: string;
  indexClassificationId: number;
  indexCountryIso: string;
  indexCreationSourceId: number;
  indexCurrency: string;
  indexCusip: string;
  indexEsgRelated: boolean;
  indexEsmaRegistered: boolean;
  indexFamilyId: number;
  indexGroupId: number;
  indexInceptionDate: string;
  indexInfo1: string;
  indexInfo2: string;
  indexIsActive: boolean;
  indexIsin: string;
  indexIssuerCode: string;
  indexIssuerId: number;
  indexKindId: number;
  indexMaturityBand: string;
  indexName: string;
  indexRating: string;
  indexRegion: string;
  indexReview: boolean;
  indexSftpFolder: string;
  indexSustainabilityId: number;
  indexSymbol: string;
  indexTickerCode: string;
  indexType: string;
  indexValor: string;
  indexWeightingMethod: string;
  indexConstituentPricesUsed: string;
  indexSector: string;
  indexUnderlying: string;
}

export const ACTIVE_FLAG_YES = 1;
export const LICENSED_FLAG_YES = 1;
export const ININDEXREVIEW_FLAG_YES = 1;

export class IndexParams {
  public static FILTER_ACTIVE_IDS = 'filterActiveIds';
  public static FILTER_FAMILY_IDS = 'filterFamilyIds';
  public static FILTER_GROUP_IDS = 'filterGroupIds';
  public static FILTER_ININDEXREVIEW = 'filterInindexreviewIds';
  public static FILTER_ISSUER_IDS = 'filterIssuerIds';
  public static FILTER_LICENSED_IDS = 'filterLicensedIds';
  public static FILTER_POTENTIALRISK_IDS = 'filterPotentialriskIds';
  public static FILTER_PACKAGE_IDS = 'filterPackageIds';
  public static FILTER_PACKAGETYPE_IDS = 'filterPackagetypeIds';
  public static FILTER_TICKERCURRENCIES = 'filterTickercurrencies';
  public static FILTER_CALCULATIONS = 'filterCalculations';
  public static FILTER_TICKERVARIANT = 'filterTickervariants';
  public static FILTER_TYPE_IDS = 'filterTypeIds';
}
