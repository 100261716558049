export class TypesKind {
  public static ACCOUNT = 1;
  public static BILLINGACCOUNT = 2;
  public static CONTACT = 3;
  public static CONTRACT = 4;
  public static LICENSE = 5;
  public static ORGANISATION = 6;
  public static SERVICE = 7;
  public static SITE = 8;
  public static PLATFORM = 9;
  public static USER = 10;
  public static USERGROUP = 11;
  public static SYSTEM = 12;
  public static TAX = 13;
  public static LICENSEGROUP = 14;
  public static SECTOR = 15;
  public static STATUS = 16;
  public static CONTRACTSUBTYPE = 30;
  public static COSTCENTRE = 32;
  public static DASHBOARDTYPE = 34;
  public static FILETYPE = 33;
  public static BUSINESSPARTNER = 35;
  public static TASK = 36;
  public static INTERFACEUSER = 48;
}
